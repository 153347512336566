import { useContext } from 'react';
import { StyledCard } from '../../../components/StyledCard/StyledCard.styled';
import Box from '@mui/material/Box';
import {
  StyledH3,
  StyledText,
} from '../../../components/Typography/Typography.styled';
import { StyledTextField } from '../../../components/StyledTextField/StyledTextField.styled';
import SharedDataContext from '../../../context/SharedDataContext';
import SuggestionPills from '../../../components/SuggestionPills/SuggestionPills';

type BusinessProblemProps = {
  handleSubmit: () => void;
};

const BusinessProblem = ({ handleSubmit }: BusinessProblemProps) => {
  const { businessProblem, updateBusinessProblem } =
    useContext(SharedDataContext);

  const handleSuggestionClick = (suggestion: string) => {
    updateBusinessProblem(suggestion);
    const input = document.getElementById('businessProblem');
    input?.focus();
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!businessProblem) return;
    if (event.key === 'Enter') {
      handleSubmit();
      event.preventDefault();
    }
  };

  const suggestions = [
    'Improve my revenues',
    'Reduce my costs',
    'Give me recommendations for better performance',
    'Help me increase the target',
    'Help me reduce the target',
  ];

  return (
    <StyledCard>
      <StyledH3>Business Problem</StyledH3>
      <StyledText>Describe how we can help you.</StyledText>
      <Box sx={{ paddingTop: '0.75rem' }}>
        <SuggestionPills
          suggestions={suggestions}
          handleClick={handleSuggestionClick}
        />
      </Box>
      <StyledTextField
        sx={{ marginTop: '0.25rem' }}
        id="businessProblem"
        placeholder="How can I improve my sales?"
        value={businessProblem}
        onChange={(event) => updateBusinessProblem(event.target.value)}
        onKeyDown={handleKeydown}
      ></StyledTextField>
    </StyledCard>
  );
};

export default BusinessProblem;
