import { useMemo } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ModuleRegistry,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from '@ag-grid-community/core';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './agGridStyles.css';
import { useTheme } from '@mui/material/styles';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

type CustomAgGridProps = {
  columnDefs: ColDef[];
  rowData: any[];
  height?: string;
  width?: string;
  marginTop?: string;
  autoSizeStrategy?:
    | SizeColumnsToContentStrategy
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy;
};

const CustomAgGrid = ({
  columnDefs,
  rowData,
  height,
  width,
  marginTop,
  autoSizeStrategy = { type: 'fitCellContents' },
  ...props
}: CustomAgGridProps) => {
  const theme = useTheme();
  const gridStyle = useMemo(
    () => ({
      height: height ?? 'auto',
      maxWidth: '100%',
      minHeight: '150px',
      width: width ?? 'auto',
      marginTop: marginTop,
    }),
    [],
  );

  const defaultColDef = useMemo(() => {
    return {
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  return (
    <div
      className={
        theme.palette.mode === 'light'
          ? 'ag-theme-alpine'
          : 'ag-theme-alpine-dark'
      }
      style={gridStyle}
    >
      <AgGridReact
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={rowData}
        pagination={true}
        paginationPageSize={20}
        autoSizeStrategy={autoSizeStrategy}
        domLayout={height ? 'normal' : 'autoHeight'}
        {...props}
      ></AgGridReact>
    </div>
  );
};

export default CustomAgGrid;
