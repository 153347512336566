import { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: 9999,
  cursor: 'pointer',
}));
const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
}));

const StyledPillsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  padding: 0,
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

type SuggestionPillsProps = {
  suggestions: string[];
  handleClick: (suggestion: string) => void;
};

const SuggestionPills: React.FC<SuggestionPillsProps> = ({
  suggestions,
  handleClick,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const pillsContainerRef = useRef<HTMLDivElement | null>(null);

  const handleArrowClick = (direction: 'left' | 'right') => {
    if (pillsContainerRef.current) {
      const container = pillsContainerRef.current;
      const containerWidth = container.offsetWidth;
      const scrollStep = containerWidth / 1.5;
      const newScrollPosition =
        direction === 'left'
          ? Math.max(0, scrollPosition - scrollStep)
          : Math.min(
              container.scrollWidth - containerWidth,
              scrollPosition + scrollStep,
            );

      setScrollPosition(newScrollPosition);
      container.scrollLeft = newScrollPosition;
    }
  };

  return (
    <StyledContainer>
      {scrollPosition ? (
        <IconButton
          aria-label="scroll left"
          disabled={scrollPosition === 0}
          onClick={() => handleArrowClick('left')}
        >
          <ArrowLeftIcon />
        </IconButton>
      ) : null}
      <StyledPillsContainer ref={pillsContainerRef}>
        {suggestions.map((suggestion, index) => (
          <StyledChip
            key={index}
            label={suggestion}
            onClick={() => handleClick(suggestion)}
          />
        ))}
      </StyledPillsContainer>

      <IconButton
        aria-label="scroll right"
        onClick={() => handleArrowClick('right')}
      >
        <ArrowRightIcon />
      </IconButton>
    </StyledContainer>
  );
};

export default SuggestionPills;
