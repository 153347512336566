import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

export const StyledFooter = styled('footer')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  paddingTop: '2rem',
  textAlign: 'center',
  width: '100%', // Take full width
  position: 'relative',
  marginTop: 'auto',
}));

export const StyledTooltipContent = styled(Typography)(() => ({
  fontSize: '0.75rem',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
}));

export const LightTooltip = styled(Tooltip)(() => ({
  marginBottom: '-0rem',
}));
