import React, {
  useState,
  useEffect,
  useContext,
  MutableRefObject,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import RateReviewIcon from '@mui/icons-material/RateReview';
import QuizIcon from '@mui/icons-material/Quiz';
import LogoutIcon from '@mui/icons-material/Logout';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import {
  StyledAppBar,
  StyledToolbar,
  SelectedTabTitle,
  StyledContainer,
  StyledMenu,
  StyledMenuIconButton,
  StyledMenuItem,
} from './Header.styled';
import { UserAvatar } from '../Avatar/Avatar.styled';
import { StyledDivider } from '../Divider/Divider.styled';
import { StyledText } from '../Typography/Typography.styled';
import { HeaderNameItems } from '../../constants';
import { UploadAxiosContext } from '../../context/AxiosContext';
import SharedDataContext from '../../context/SharedDataContext';
import { getClientSettings } from '../../utils/getClientSettings';

interface HeaderProps {
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>;
  toggleTheme: () => void;
}

const Header = ({ scrollContainerRef, toggleTheme }: HeaderProps) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [elevation, setElevation] = useState(0);
  const uploadAxiosInstance = useContext(UploadAxiosContext);
  const { resetUserSession } = useContext(SharedDataContext);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const currentScroll = scrollContainerRef?.current.scrollTop;
        setElevation(currentScroll > 0 ? 4 : 0);
      }
    };
    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener('scroll', handleScroll);
    return () => scrollContainer?.removeEventListener('scroll', handleScroll);
  }, [scrollContainerRef]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        if (uploadAxiosInstance === null) {
          return;
        }
        await uploadAxiosInstance.post('/register', {});
        if (isMounted) {
          setHasRegistered(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!hasRegistered) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [uploadAxiosInstance, hasRegistered]);

  const location = useLocation();
  const currentTab = HeaderNameItems.find(
    (item) => item.compPath === location.pathname,
  );
  // console.log(currentTab);
  const currentTabName = currentTab ? currentTab.name : '';

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    resetUserSession();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return (
    <>
      <StyledAppBar elevation={elevation}>
        <StyledToolbar>
          <SelectedTabTitle variant="h2">{getClientSettings(user?.email || "").title || currentTabName}</SelectedTabTitle>
          <StyledContainer>
            <UserAvatar>{user?.name?.charAt(0)}</UserAvatar>
            <StyledText>Hi, {user?.name}</StyledText>
            <StyledMenu
              color="primary"
              elevation={1}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              <StyledMenuItem
                sx={{ padding: '0.75rem 1rem  0.25rem  1rem' }}
                onClick={toggleTheme}
              >
                <Brightness4Icon />
                <StyledText sx={{ fontWeight: 'bold', marginLeft: '12px' }}>
                  Toggle theme
                </StyledText>
              </StyledMenuItem>
              <StyledMenuItem
                sx={{ padding: '0.25rem 1rem' }}
                onClick={() => navigate('/glossary')}
              >
                <QuizIcon />
                <StyledText sx={{ fontWeight: 'bold', marginLeft: '12px' }}>
                  Glossary
                </StyledText>
              </StyledMenuItem>
              <StyledMenuItem
                sx={{ padding: '0.25rem 1rem' }}
                onClick={() => navigate('/feedback')}
              >
                <RateReviewIcon />
                <StyledText sx={{ fontWeight: 'bold', marginLeft: '12px' }}>
                  Feedback
                </StyledText>
              </StyledMenuItem>
              <StyledMenuItem
                sx={{ padding: '0.25rem 1rem' }}
                onClick={() => navigate('/terms-conditions')}
              >
                <WysiwygIcon />
                <StyledText sx={{ fontWeight: 'bold', marginLeft: '12px' }}>
                  Terms & Conditions
                </StyledText>
              </StyledMenuItem>
              <StyledMenuItem
                sx={{ padding: '0.25rem 1rem 0.75rem 1rem' }}
                disableTouchRipple
                onClick={handleLogout}
              >
                <LogoutIcon />
                <StyledText sx={{ fontWeight: 'bold', marginLeft: '12px' }}>
                  Logout
                </StyledText>
              </StyledMenuItem>
            </StyledMenu>
            <StyledMenuIconButton
              size="large"
              edge="start"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </StyledMenuIconButton>
          </StyledContainer>
        </StyledToolbar>
      </StyledAppBar>
      <StyledDivider />
    </>
  );
};

export default Header;
