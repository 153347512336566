import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputBase-root': {
    borderColor: theme.palette.divider, // Sets the default border color
    '&:focus': {
      borderColor: theme.palette.primary.main, // Changes border color on focus
    },
  },
  width: '100%',
  marginTop: '1rem',
}));
