import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TargetVariableSelect from './components/TargetVariableSelect';
import IndustrySelect from './components/IndustrySelect';
import BusinessProblem from './components/BusinessProblem';
import { StyledButton } from '../../components/Button/Button.styled';
import SharedDataContext from '../../context/SharedDataContext';
import AnalysisCompletionFlagsContext from '../../context/AnalysisCompletionFlags';
import { AnalysisAxiosContext } from '../../context/AxiosContext';
import ApiStatus, { ApiLoadingState } from './components/ApiStatus';
import LLMSelect from './components/LLMSelect';
import { toast } from 'react-toastify';
import useScroll from '../../hooks/useScroll';
import { StyledCard } from '../../components/StyledCard/StyledCard.styled';
import FileUpload from './components/FileUpload';
import CSVPreview from './components/CsvPreview';

const DataUpload = () => {
  const navigate = useNavigate();
  const analysisAxiosInstance = useContext(AnalysisAxiosContext);
  const {
    sessionId,
    userSub,
    csvUrl,
    // updateCSVurl,
    uploadedCSVName,
    uploadedPDFName,
    selectedVariable,
    businessProblem,
  } = useContext(SharedDataContext);
  const {
    updateIsAutoMLAnalysisDone,
    updateIsDescriptiveStatsDone,
    updateIsLinearModelDone,
    problemType,
  } = useContext(AnalysisCompletionFlagsContext);
  // const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [loading, setLoading] = useState<ApiLoadingState>({
    call1: 'waiting',
    call2: 'waiting',
    call3: 'waiting',
  });
  const [errors, setErrors] = useState({
    call1: undefined,
    call2: undefined,
    call3: undefined,
  });
  const [headers, setHeaders] = useState<string[]>([]);
  const previewRef = useScroll(loading);

  useEffect(() => {
    setLoading({
      call1: 'waiting',
      call2: 'waiting',
      call3: 'waiting',
    });
    setErrors({
      call1: undefined,
      call2: undefined,
      call3: undefined,
    });
  }, [uploadedCSVName, selectedVariable]);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleApiCalls = async () => {
    if (!analysisAxiosInstance || !userSub) return;

    const fullCSVUrl = `s3://${process.env.AWS_BUCKET_NAME}/${uploadedCSVName}`;

    const endpoints = [
      {
        url: '/descriptive_stats',
        updateFn: updateIsDescriptiveStatsDone,
        queryString: `session_id=${encodeURIComponent(sessionId)}&csv_url=${encodeURIComponent(fullCSVUrl)}&user_sub=${encodeURIComponent(userSub)}`,
      },
      {
        url: '/automl_analysis',
        updateFn: updateIsAutoMLAnalysisDone,
        queryString: `session_id=${encodeURIComponent(sessionId)}&csv_url=${encodeURIComponent(fullCSVUrl)}&user_sub=${encodeURIComponent(userSub)}&target_column=${encodeURIComponent(selectedVariable)}`,
      },
      {
        url: '/linear_model',
        updateFn: updateIsLinearModelDone,
        queryString: `session_id=${encodeURIComponent(sessionId)}&csv_url=${encodeURIComponent(fullCSVUrl)}&user_sub=${encodeURIComponent(userSub)}&target_column_name=${encodeURIComponent(selectedVariable)}`,
      },
    ];

    let allCallsSuccessful = true;
    for (let i = 0; i < endpoints.length; i++) {
      const { url, updateFn, queryString } = endpoints[i];
      const callKey = `call${i + 1}`;
      setLoading((prev) => ({ ...prev, [callKey]: 'processing' }));
      const endpointUrl = `${url}?${queryString}`;
      try {
        const result = await analysisAxiosInstance.get(endpointUrl);
        if (result.data.status_code !== 200) {
          throw new Error('API call did not return a status code of 200');
        }
        updateFn(true);
        setLoading((prev) => ({
          ...prev,
          [callKey]: 'success',
        }));
        // Wait for 2 seconds before the next API call
        if (i < endpoints.length - 1) await delay(2000);
      } catch (error: any) {
        allCallsSuccessful = false; // Update flag on error
        toast.error(`${callKey} API call failed.`);
        setErrors((prev) => ({
          ...prev,
          [callKey]: error.response?.data.message || error.message,
        }));
        setLoading((prev) => ({
          ...prev,
          [callKey]: 'error',
        }));
        break;
      }
    }

    // Check if all calls were successful after the loop
    if (allCallsSuccessful) {
      navigate('/recommendations');
    } else {
      // Log or handle the case where not all calls were successful
      console.error('Not all API calls were successful.');
    }
  };

  const handleSubmit = () => {
    // TODO: Remove when support for classification problem type is added
    if (problemType !== 'Regression') {
      toast.error('Only Regression problems are supported at the moment.');
      toast.info('Please select a different target variable.');
      return;
    }
    // Reset loading state
    setLoading({
      call1: 'waiting',
      call2: 'waiting',
      call3: 'waiting',
    });
    setErrors({
      call1: undefined,
      call2: undefined,
      call3: undefined,
    });
    if (analysisAxiosInstance && userSub) {
      handleApiCalls();
    }
  };

  const displayApiStatus =
    loading.call1 !== 'waiting' ||
    loading.call2 !== 'waiting' ||
    loading.call3 !== 'waiting';

  return (
    <>
      <StyledCard>
        <FileUpload>Choose CSV, Excel, or PDF files to be analyzed</FileUpload>
        <CSVPreview
          headers={headers}
          setHeaders={setHeaders}
          csvUrl={csvUrl}
          uploadedCSVName={uploadedCSVName}
        />
      </StyledCard>
      {uploadedCSVName && (
        <>
          <TargetVariableSelect variables={headers} />
          <IndustrySelect />
        </>
      )}
      {(uploadedCSVName || uploadedPDFName) && <LLMSelect />}
      {uploadedCSVName && selectedVariable && (
        <BusinessProblem handleSubmit={handleSubmit} />
      )}
      {businessProblem && (
        <StyledButton
          sx={{ marginBottom: '0.5rem' }}
          onClick={handleSubmit}
          disabled={displayApiStatus}
        >
          Scavenger It !
        </StyledButton>
      )}
      {displayApiStatus && (
        <Box
          ref={previewRef}
          sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <ApiStatus loading={loading} errors={errors} />
        </Box>
      )}
    </>
  );
};

export default DataUpload;
