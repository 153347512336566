import { useEffect, useRef } from 'react';

const useScroll = (dependency: any, block?: ScrollLogicalPosition) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollContainer = () => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: block || 'end',
        });
      }
    };

    const delayedScroll = setTimeout(scrollContainer, 100);

    return () => {
      clearTimeout(delayedScroll);
    };
  }, [dependency]);

  return containerRef;
};

export default useScroll;
