import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { green, red } from '@material-ui/core/colors';
import {
  StyledText,
  StyledH4,
  StyledH3,
} from '../../../components/Typography/Typography.styled';
import { StyledApiBox } from '../DataUpload.styled';

export type LoadingState = 'waiting' | 'processing' | 'success' | 'error';
export type ApiLoadingState = {
  call1: LoadingState;
  call2: LoadingState;
  call3: LoadingState;
};
type ApiStatusProps = {
  loading: ApiLoadingState;
  errors: { [key in keyof ApiLoadingState]?: string };
};

const statusIcons = {
  waiting: <PauseCircleOutlineIcon />,
  processing: <CircularProgress size={20} />,
  success: <CheckCircleOutlineIcon style={{ color: green[500] }} />,
  error: <ErrorOutlineIcon style={{ color: red[500] }} />,
};

const ApiStatus = ({ loading, errors }: ApiStatusProps) => {
  const messages = {
    call1: {
      heading: 'Descriptive Statistics Analysis',
      waiting: 'Awaiting to start descriptive stats analysis...',
      processing: 'Processing descriptive statistics...',
      success: 'Descriptive statistics analysis completed successfully.',
      error:
        errors?.call1 ||
        'An error occurred during descriptive statistics analysis.',
    },
    call2: {
      heading: 'AutoML Analysis',
      waiting: 'Awaiting to start AutoML analysis...',
      processing: 'Performing AutoML analysis...',
      success: 'AutoML analysis completed successfully.',
      error: errors?.call2 || 'An error occurred during AutoML analysis.',
    },
    call3: {
      heading: 'Linear Model Analysis',
      waiting: 'Awaiting to start linear model analysis...',
      processing: 'Calculating linear model...',
      success: 'Linear model analysis completed successfully.',
      error: errors?.call3 || 'An error occurred during linear model analysis.',
    },
  };

  return (
    <StyledApiBox>
      {(Object.keys(loading) as Array<keyof ApiLoadingState>).map((call) => {
        const status: LoadingState = loading[call];
        const IconElement = statusIcons[status]; // Directly use the stored JSX element
        return (
          <Box key={call} sx={{}}>
            <StyledH4
              sx={{
                fontWeight: 'bold',
                // margin,
              }}
            >
              {messages[call].heading}
            </StyledH4>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {IconElement}
              <StyledText
                style={{ transition: 'opacity 0.3s ease', marginLeft: '8px' }}
              >
                {messages[call][status]}
              </StyledText>
            </Box>
          </Box>
        );
      })}
    </StyledApiBox>
  );
};

export default ApiStatus;
