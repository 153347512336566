// SidebarItem.jsx
import {
  StyledSidebarItem,
  StyledSidebarIcon,
  StyledSidebarText,
} from './Sidebar.styled';

type SidebarItemProps = {
  text: string;
  icon: JSX.Element;
  isenabled: boolean;
  isselected: boolean;
  path: string;
};

const SidebarItem = ({
  text,
  icon,
  isenabled,
  isselected,
  path,
}: SidebarItemProps) => {
  return (
    <StyledSidebarItem
      isselected={isselected}
      isenabled={isenabled}
      to={isenabled ? path : '#'}
    >
      <StyledSidebarIcon isselected={isselected} isenabled={isenabled}>
        {icon}
      </StyledSidebarIcon>
      <StyledSidebarText isselected={isselected} isenabled={isenabled}>
        {text}
      </StyledSidebarText>
    </StyledSidebarItem>
  );
};

export default SidebarItem;
