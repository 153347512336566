// Sidebar.styled.js
import { Theme, styled } from '@mui/material/styles';
import { StyledH1, StyledText } from '../Typography/Typography.styled';
import { Link } from 'react-router-dom';

export const SidebarContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default, // light gray
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem',
}));

export const StyledList = styled('div')(({ theme }) => ({
  zIndex: 1,
  width: '270px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}}`,
  boxShadow: theme.shadows[1],
  padding: '1rem',
  flexGrow: '1',
}));

export const StyledSidebarItem = styled(Link)(
  ({
    theme,
    isselected,
    isenabled,
  }: {
    theme?: Theme;
    isselected: boolean;
    isenabled: boolean;
  }) => ({
    textDecoration: 'none',
    color: isenabled ? theme?.palette.text.primary : theme?.palette.grey[400],
    cursor: isenabled ? 'pointer' : 'default',
    display: 'flex',
    alignItems: 'center',
    padding: theme?.spacing(1, 2),
    height: theme?.spacing(7),
    borderRadius: theme?.shape.borderRadius,
    backgroundColor: isselected ? theme?.palette.primary.main : 'transparent',
    '&:hover': {
      color: isenabled ? theme?.palette.common.white : theme?.palette.grey[500],
      backgroundColor: isenabled ? theme?.palette.primary.light : 'transparent',
    },
    // Add any other styles you need here
  }),
);

export const StyledSidebarIcon = styled('div')(({
  theme,
  isselected,
  isenabled,
}: {
  theme?: Theme;
  isselected: boolean;
  isenabled: boolean;
}) => {
  let color;
  if (isselected) {
    color = theme?.palette.common.white;
  } else if (isenabled) {
    color = theme?.palette.primary.main;
  } else {
    color = theme?.palette.grey[500];
  }

  return {
    marginRight: theme?.spacing(2),
    color: color,
  };
});

export const StyledSidebarText = styled(StyledText)(({
  theme,
  isselected,
  isenabled,
}: {
  theme?: Theme;
  isselected: boolean;
  isenabled: boolean;
}) => {
  const fontWeight = isselected ? theme?.typography.fontWeightBold : 'normal';

  let color;
  let hoverColor;
  if (isselected) {
    color = theme?.palette.common.white;
    hoverColor = theme?.palette.common.white;
  } else if (isenabled) {
    color = theme?.typography.body1.color;
    hoverColor = theme?.palette.common.white;
  } else {
    color = theme?.palette.grey[500];
    hoverColor = theme?.palette.grey[500];
  }

  return {
    flex: 1,
    fontWeight: fontWeight,
    color: color,
    '&:hover': {
      color: hoverColor,
    },
  };
});

export const LogoContainer = styled('div')(() => ({
  padding: '1rem 0 0.5rem 0',
  display: 'flex',
  gap: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  cursor: 'pointer',
}));

export const CompanyName = styled(StyledH1)(() => ({
  fontSize: '2rem',
}));
