// csvParser.js
import Papa, { ParseResult } from 'papaparse';

export interface CSVRow {
  id: number;
  [key: string]: string | number;
}

interface CSVResult {
  data: CSVRow[];
  fields: string[];
}

export const parseCSV = (csvString: string): Promise<CSVResult> => {
  return new Promise((resolve, reject) => {
    if (!csvString) {
      reject(new Error('No CSV string provided'));
      return;
    }
    Papa.parse(csvString, {
      delimiter: ',',
      newline: '\n',
      header: true,
      // dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result: ParseResult<any>) => {
        const fields = result.meta.fields
          ? result.meta.fields.map((field) => field.trim())
          : [];
        const dataWithIds = result.data.map((row, index) => {
          return {
            id: index,
            ...row,
          };
        });

        resolve({ data: dataWithIds, fields: fields });
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
};

export type tableDataRows = {
  [key: string]: {
    [index: string]: string | number;
  };
};

export type tableDataCols = {
  field: string;
  headerName: string;
  pinned?: 'left' | 'right';
};

export const transformDataForGrid = (jsonData: any) => {
  if (!jsonData || typeof jsonData !== 'object') {
    throw new Error('Invalid JSON data');
  }

  const statistics = Object.keys(jsonData);
  const firstStatRecords = jsonData[statistics[0]];
  const isCategory = isNaN(parseInt(Object.keys(firstStatRecords)[0]));

  // Preparing columns for the grid
  let columns: tableDataCols[];

  if (isCategory) {
    columns = [
      {
        field: 'rowHeader',
        headerName: 'CATEGORY',
        pinned: 'left',
      },
      ...statistics.map((stat) => ({
        field: stat,
        headerName: stat.toUpperCase(),
      })),
    ];
  } else {
    columns = statistics.map((stat) => ({
      field: stat,
      headerName: stat.toUpperCase(),
    }));
  }

  // Transforming data for rows
  const rows = Object.keys(firstStatRecords).map((key, index) => {
    const row: { [key: string]: any } = { id: index };

    if (isCategory) {
      row['rowHeader'] = key.toUpperCase();
    }

    statistics.forEach((stat) => {
      row[stat] = jsonData[stat][key];
    });
    return row;
  });

  return { columns, rows };
};
