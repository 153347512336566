import {
  StyledFooter,
  StyledTooltipContent,
  LightTooltip,
} from './Footer.styled.js';
import { StyledText } from '../Typography/Typography.styled';
import Zoom from '@mui/material/Zoom';

const Footer = () => {
  return (
    <StyledFooter>
      <LightTooltip
        title={
          <StyledTooltipContent>
            This software is provided for informational and facilitative puposes
            only. It is not intended to replace professional business advice or
            judgement. Users are advised to excercise thier own discretion and
            seek independent expert consultation where necessary. Users are
            responsible for verifying the relevance and accuracy of the
            information in the context of their specific business circumstances.
          </StyledTooltipContent>
        }
        TransitionComponent={Zoom}
        placement="top"
        arrow
      >
        <StyledText sx={{ fontSize: '0.75rem' }}>
          Scavenger can make mistakes. Consider checking important information.
        </StyledText>
      </LightTooltip>
    </StyledFooter>
  );
};

export default Footer;
