import {
  createTheme,
  ThemeOptions,
  darken,
  lighten,
} from '@mui/material/styles';

const boxShadow: string = 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem';
// Common Typography
const fontHeading = "'Roboto Mono', monospace";
const fontBody = "'Roboto', 'Helvetica', monospace";
//Common Colors
const primaryColor = '#12b6aa';
const secondaryColor = '#342177';

// Light and Dark Color Schemes
const lightColors = {
  bgCardColor: 'rgba(255, 255, 255, 0.95)',
  bgGrayColor: '#f8f9fa',
  primaryText: '#000000',
  secondaryText: '#444444',
};

const darkColors = {
  bgGrayColor: '#181D1F',
  bgCardColor: '#222628',
  primaryText: '#FFFFFF',
  secondaryText: '#BBBBBB',
};

// Common styles (applied to both light and dark themes)
const commonStyles: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: primaryColor,
          '&:hover': {
            backgroundColor: darken(primaryColor, 0.2), // A darker shade of primary color for hover state
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderColor: secondaryColor, // Secondary color for borders
            '&:focus': {
              borderColor: lighten(primaryColor, 0.2), // Lighter shade of primary color for focus state
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: lighten(primaryColor, 0.3),
          color: 'white',
          maxWidth: 1024,
          padding: '0.75rem',
          borderRadius: '10px',
          popper: {
            marginBottom: lighten(primaryColor, 0.3),
          },
        },
        arrow: {
          color: lighten(primaryColor, 0.3),
        },
      },
    },
  },
  shape: { borderRadius: 10 },
};

// Dark theme specific settings
const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
    background: {
      default: darkColors.bgGrayColor,
      paper: darkColors.bgCardColor,
    },
    divider: lighten(darkColors.bgCardColor, 0.05),
    text: { primary: darkColors.primaryText },
  },
  typography: {
    h1: {
      fontFamily: fontHeading,
      fontWeight: 'bold',
      fontSize: '36px',
      color: primaryColor,
    },
    h2: {
      fontFamily: fontHeading,
      fontWeight: '600', // Semi-Bold
      fontSize: '30px',
      color: primaryColor,
    },
    h3: {
      fontFamily: fontBody,
      fontWeight: 'bold',
      fontSize: '24px',
      color: darkColors.primaryText,
    },
    h4: {
      fontFamily: fontBody,
      fontWeight: 'normal',
      fontSize: '20px',
      color: darkColors.primaryText,
    },
    body1: {
      fontFamily: fontBody,
      fontWeight: 'normal',
      fontSize: '16px',
      color: darkColors.primaryText,
    },
    button: {
      fontFamily: fontBody,
      fontWeight: 'bold',
      fontSize: '14px',
    },
  },
};

// Light theme specific settings
const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
    background: {
      default: lightColors.bgGrayColor,
      paper: lightColors.bgCardColor,
    },
    divider: darken(lightColors.bgGrayColor, 0.05),
    text: { primary: lightColors.primaryText },
  },
  typography: {
    h1: {
      fontFamily: fontHeading,
      fontWeight: 'bold',
      fontSize: '36px',
      color: primaryColor,
    },
    h2: {
      fontFamily: fontHeading,
      fontWeight: '600', // Semi-Bold
      fontSize: '30px',
      color: primaryColor,
    },
    h3: {
      fontFamily: fontBody,
      fontWeight: 'bold',
      fontSize: '24px',
      color: lightColors.primaryText,
    },
    h4: {
      fontFamily: fontBody,
      fontWeight: 'normal',
      fontSize: '20px',
      color: lightColors.primaryText,
    },
    body1: {
      fontFamily: fontBody,
      fontWeight: 'normal',
      fontSize: '16px',
      color: lightColors.primaryText,
    },
    button: {
      fontFamily: fontBody,
      fontWeight: 'bold',
      fontSize: '14px',
    },
  },
};

const getTheme = (mode: 'light' | 'dark') => {
  const data = createTheme({
    ...commonStyles,
    ...(mode === 'dark' ? darkTheme : lightTheme),
  });
  data.shadows[1] = boxShadow;
  return data;
};

export default getTheme;
