import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(() => ({
  fontWeight: '700',
  borderRadius: '9999px',
}));
StyledButton.defaultProps = {
  variant: 'contained',
};
