import { useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import { StyledSelect, StyledMenuItem } from '../DataUpload.styled';
import { StyledCard } from '../../../components/StyledCard/StyledCard.styled';
import { StyledH3 } from '../../../components/Typography/Typography.styled';
import SharedDataContext from '../../../context/SharedDataContext';

const LLMSelect = () => {
  const { selectedLLM, updateSelectedLLM, availableLLM } =
    useContext(SharedDataContext);

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const targetValue = event.target.value;
    updateSelectedLLM(targetValue);
  };

  return (
    <StyledCard>
      <StyledH3 sx={{ marginBottom: '0.75rem' }}>LLM Selection </StyledH3>
      <FormControl fullWidth>
        <StyledSelect
          labelId="variable-select-label"
          id="variable-select"
          value={selectedLLM}
          onChange={(event: any) => handleSelectChange(event)}
        >
          {availableLLM.map((llm) => (
            <StyledMenuItem key={llm} value={llm}>
              {llm.toUpperCase()}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </StyledCard>
  );
};

export default LLMSelect;
