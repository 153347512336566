import { useState, Suspense, lazy, useRef, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import getTheme from './themes/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SharedDataProvider from './context/SharedDataProvider';
import { AxiosProvider, ClientSettingsContext } from './context/AxiosContext';
import { StyledBox } from './components/StyledBox/StyledBox.styled';

import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import DataUpload from './routes/DataUpload/DataUpload';
import Footer from './components/Footer/Footer';
import MainLoading from './components/Loading/MainLoading';
import ComponentLoading from './components/Loading/ComponentLoading';
import TermsConditions from './routes/TermsConditions/TermsConditions';
import FeedbackForm from './routes/FeedbackForm/FeedbackForm';

const DataOverview = lazy(() => import('./routes/DataOverview/DataOverview'));
const MachineLearning = lazy(
  () => import('./routes/MachineLearning/MachineLearning'),
);
const LinearRegression = lazy(
  () => import('./routes/LinearRegression/LinearRegression'),
);
const Recommendations = lazy(
  () => import('./routes/Recommendations/Recommendations'),
);
const ChatWithData = lazy(() => import('./routes/ChatWithData/ChatWithData'));
const Glossary = lazy(() => import('./routes/Glossary/Glossary'));
const Dashboard = lazy(() => import('./routes/Dashboard/Dashboard'));

const MainComponent = () => {
  const location = useLocation();
  const scrollContainerRef = useRef(null);
  const ContextValue = useContext(ClientSettingsContext);

  const showAllSidebarItems = ContextValue?.showAllSidebarItems;

  const prefersDarkMode =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;

  const [themeMode, setThemeMode] = useState<'dark' | 'light'>(
    prefersDarkMode ? 'dark' : 'light',
  );
  const currentTheme = getTheme(themeMode);

  const toggleTheme = () => {
    setThemeMode(themeMode === 'dark' ? 'light' : 'dark');
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <Box display="flex" maxHeight="100vh">
        <Sidebar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            overflow: 'auto',
          }}
          ref={scrollContainerRef}
        >
          <Header
            scrollContainerRef={scrollContainerRef}
            toggleTheme={toggleTheme}
          // theme={themeMode}
          />
          <StyledBox>
            <Suspense fallback={<ComponentLoading />}>
              <Routes>
                {
                  showAllSidebarItems ? (
                    <>
                      <Route path="/" element={<DataUpload />} />
                      <Route path="/data-overview" element={<DataOverview />} />
                      <Route
                        path="/machine-learning"
                        element={<MachineLearning />}
                      />
                      <Route
                        path="/linear-regression"
                        element={<LinearRegression />}
                      />
                      <Route
                        path="/recommendations"
                        element={<Recommendations />}
                      />
                    </>
                  )
                    : (
                      <>
                        <Route path="/" element={<Navigate to="/chat" />} />
                        <Route
                          path="/dashboard"
                          element={<Dashboard />}
                        />
                      </>
                    )
                }
                <Route path="/chat" element={<ChatWithData />} />
                <Route path="/glossary" element={<Glossary />} />
                <Route path="/feedback" element={<FeedbackForm />} />
                <Route
                  path="/terms-conditions"
                  element={<TermsConditions />}
                />
              </Routes>
            </Suspense>
            {location.pathname !== '/chat' && <Footer />}
          </StyledBox>
          <ToastContainer autoClose={2000} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const AuthenticatedRoot = withAuthenticationRequired(MainComponent, {
  onRedirecting: () => <MainLoading />,
});

function App() {
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN ?? ''}
      clientId={process.env.AUTH0_CLIENT_ID ?? ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <Router>
        <SharedDataProvider>
          <AxiosProvider>
            <AuthenticatedRoot />
          </AxiosProvider>
        </SharedDataProvider>
      </Router>
    </Auth0Provider>
  );
}

export default App;
