import { Theme, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

type StyledDropzoneProps = {
  theme?: Theme;
  disabled: boolean;
  isDragActive: boolean;
};

type StyledSelectProps = {
  theme?: Theme;
  isSelectActive?: boolean;
};

export const StyledDropzone = styled('div')(
  ({ theme, disabled, isDragActive }: StyledDropzoneProps) => ({
    minHeight: '7rem',
    display: 'flex',
    gap: '1.25rem',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${
      isDragActive ? theme?.palette.primary.main : theme?.palette.divider
    }`,
    borderRadius: theme?.shape.borderRadius,
    padding: '20px',
    textAlign: 'center',
    // backgroundColor: disabled ? theme.palette.background.paper : "white",
    backgroundColor: disabled
      ? 'theme.palette.background.default'
      : 'theme.palette.background.paper',
    color: '#666',
  }),
);

export const StyledRow = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: ' 1rem 0',
}));

export const StyledSelect = styled(Select)(
  ({ theme, isSelectActive }: StyledSelectProps) => ({
    color: theme?.palette.text.primary,
    border: `1px solid ${
      isSelectActive ? theme?.palette.primary.main : theme?.palette.divider
    }`,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSelect-icon': {
      color: theme?.palette.primary.main,
    },
  }),
);

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.MuiInputLabel-shrink': {
    color: theme.palette.primary.main,
    transform: 'translate(5px, -25px) scale(0.9)',
    fontWeight: 'bold',
  },
  '&.Mui-focused': {
    color: theme.palette.primary.main,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.primary,
  width: 'fitContent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledApiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.primary.main}`,
  margin: '2rem 0 1rem 0',
  padding: '1.5rem 2rem',
  width: '100%',
  maxWidth: '1024px',
}));
