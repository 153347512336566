import { useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
} from '../DataUpload.styled';
import { StyledCard } from '../../../components/StyledCard/StyledCard.styled';
import { StyledH3 } from '../../../components/Typography/Typography.styled';
import { industries } from '../../../constants';
import SharedDataContext from '../../../context/SharedDataContext';

const IndustrySelect = () => {
  const { selectedIndustry, updateSelectedIndustry } =
    useContext(SharedDataContext);
  return (
    <StyledCard>
      <StyledH3 sx={{ marginBottom: '0.75rem' }}>Industry Selection</StyledH3>
      <FormControl fullWidth>
        <StyledInputLabel id="variable-select-label">
          Select an industry
        </StyledInputLabel>
        <StyledSelect
          labelId="variable-select-label"
          id="variable-select"
          value={selectedIndustry}
          onChange={(event: SelectChangeEvent<any>) => {
            updateSelectedIndustry(event?.target?.value);
          }}
        >
          {industries.map((industry) => (
            <StyledMenuItem key={industry} value={industry}>
              {industry.toUpperCase()}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </StyledCard>
  );
};

export default IndustrySelect;
