import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

export const StyledCard = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  margin: '0 0 2rem 0',
  padding: '2rem',
  width: '100%',
  maxWidth: '1024px',
  zIndex: 10,
}));

export const StyledHighlightCard = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `0.5px dotted ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  marginTop: '1.5rem',
  padding: '1rem',
  width: '100%',
  maxWidth: '1024px',
}));

export const StyledSkeletonCard = styled(Skeleton)(() => ({
  height: '25rem',
  width: '100%',
  display: 'block',
  maxWidth: '1024px',
  borderRadius: '1rem',
  margin: '0 0 2rem 0',
}));
