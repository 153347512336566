import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';
import UploadFileIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/ClearOutlined';
import {
  StyledH3,
  StyledText,
  StyledCaption,
} from '../../../components/Typography/Typography.styled';
import { StyledRow, StyledDropzone } from '../DataUpload.styled';
import { toast } from 'react-toastify';
import { UploadAxiosContext } from '../../../context/AxiosContext';
import SharedDataContext from '../../../context/SharedDataContext';
import csvIcon from '../../../../public/csv.png';
import pdfIcon from '../../../../public/pdf.png';

type FileUploadProps = {
  children: React.ReactNode;
};

const FileUpload = ({ children }: FileUploadProps) => {
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
  const uploadAxiosInstance = useContext(UploadAxiosContext);
  const {
    sessionId,
    userSub,
    updateCSVurl,
    updateUploadedCSVName,
    uploadedCSVName,
    updateUploadedPDFName,
    uploadedPDFName,
    updateSelectedVariable,
    updateSelectedIndustry,
    updateBusinessProblem,
  } = useContext(SharedDataContext);

  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState<{ name: string; type: string }[]>([]);

  useEffect(() => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];

      if (uploadedCSVName) {
        const csvIndex = updatedFiles.findIndex(
          (file) => file.type === 'text/csv',
        );
        if (csvIndex !== -1) {
          updatedFiles[csvIndex] = { name: uploadedCSVName, type: 'text/csv' };
        } else {
          updatedFiles.push({ name: uploadedCSVName, type: 'text/csv' });
        }
      }

      if (uploadedPDFName) {
        const pdfIndex = updatedFiles.findIndex(
          (file) => file.type === 'application/pdf',
        );
        if (pdfIndex !== -1) {
          updatedFiles[pdfIndex] = {
            name: uploadedPDFName,
            type: 'application/pdf',
          };
        } else {
          updatedFiles.push({ name: uploadedPDFName, type: 'application/pdf' });
        }
      }

      return updatedFiles;
    });
  }, [uploadedCSVName, uploadedPDFName]);

  const uploadFile = async (file: File) => {
    if (!uploadAxiosInstance || !userSub) return;
    try {
      setIsUploading(true);
      const formData = new FormData();

      if (
        file.type === 'text/csv' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        formData.append('csv_file', file, file.name);
      } else if (file.type === 'application/pdf') {
        formData.append('pdf_file', file, file.name);
      }
      formData.append('user_sub', userSub);
      formData.append('session_id', sessionId);

      const response = await uploadAxiosInstance.post('/upload', formData);
      if (response.status === 200) {
        if (
          file.type === 'text/csv' ||
          file.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          let file_name = response.data.csv_filename.replace('.xlsx', '.csv');
          updateUploadedCSVName(file_name);
          updateCSVurl(response.data.uploaded_csv_url);
        }
        if (file.type === 'application/pdf') {
          updateUploadedPDFName(response.data.pdf_filename);
        }

        toast.success('File uploaded successfully.');
        setIsUploading(false);
        return response;
      } else {
        setIsUploading(false);
        return;
      }
    } catch (error) {
      toast.error('Error uploading files.');
      console.error('Upload error:', error);
      setIsUploading(false);
      return;
    }
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      // Handle file rejections
      fileRejections.forEach((file: any) => {
        file.errors.forEach((error: any) => {
          if (error.code === 'file-too-large') {
            toast.error('File is too large.');
          } else if (error.code === 'file-invalid-type') {
            toast.error('Invalid file type.');
          }
        });
      });

      acceptedFiles.forEach((file: File) => {
        if (
          (file.type === 'text/csv' ||
            file.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
          !uploadedCSVName
        ) {
          uploadFile(file);
        } else if (file.type === 'application/pdf' && !uploadedPDFName) {
          uploadFile(file);
        } else {
          toast.error('Only one CSV and one PDF file can be uploaded.');
        }
      });
    },
    [uploadAxiosInstance, uploadedCSVName, uploadedPDFName, uploadFile],
  );

  const fileTypes: Accept = {
    'text/csv': ['.csv', '.xlsx', '.xls'],
    'application/pdf': ['.pdf'],
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileTypes,
    maxSize: MAX_FILE_SIZE,
    multiple: true,
    disabled: files.length > 1, // Allow two files for now
  });

  const handleDeleteFile = (event: React.MouseEvent, index: number) => {
    event.stopPropagation();
    const updatedFiles = [...files];
    const removedFile = updatedFiles.splice(index, 1)[0];
    setFiles(updatedFiles);
    if (removedFile.type === 'text/csv') {
      updateUploadedCSVName('');
      updateSelectedVariable('');
      updateCSVurl('');
      updateSelectedIndustry('');
      updateBusinessProblem('');
      toast.info('All fields have been cleared.');
    }
    if (removedFile.type === 'application/pdf') {
      updateUploadedPDFName('');
    }
    toast.success('File deleted successfully.');
  };

  const renderContent = () => {
    if (files.length > 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <UploadFileIcon color="primary" fontSize="large" />
            <Box>
              <StyledText>
                Drag and drop files here or click to upload
              </StyledText>
              <StyledCaption style={{ marginTop: '5px' }}>
                <i>Accepted file types: .csv, .xlsx, .pdf</i>
              </StyledCaption>
            </Box>
          </Box>
          {files.length > 0 && (
            <Box
              sx={{
                width: '100%',
                marginTop: '1rem',
                overflowWrap: 'anywhere',
              }}
            >
              {files.map((file, index) => (
                <StyledRow key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '0.5rem',
                      alignItems: 'center',
                    }}
                  >
                    {file.type === 'text/csv' ? (
                      <img
                        src={csvIcon}
                        alt="CSV Icon"
                        style={{ width: '24px', height: '24px' }}
                      />
                    ) : (
                      <img
                        src={pdfIcon}
                        alt="PDF Icon"
                        style={{ width: '24px', height: '24px' }}
                      />
                    )}
                    <StyledText sx={{ textAlign: 'start' }}>
                      {file.name}
                    </StyledText>
                  </Box>
                  <DeleteIcon
                    color="primary"
                    onClick={(event) => handleDeleteFile(event, index)}
                    sx={{ cursor: 'pointer' }}
                  />
                </StyledRow>
              ))}
            </Box>
          )}
          {isUploading && <CircularProgress color="primary" />}
        </Box>
      );
    } else if (isDragActive) {
      return <StyledText>Drop the file here ...</StyledText>;
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <UploadFileIcon color="primary" fontSize="large" />
            <Box>
              <StyledText>
                Drag and drop files here or click to upload
              </StyledText>
              <StyledCaption style={{ marginTop: '5px' }}>
                <i>Accepted file types: .csv, .xlsx, .pdf</i>
              </StyledCaption>
            </Box>
          </Box>
          {isUploading && (
            <CircularProgress color="primary" sx={{ marginTop: '1rem' }} />
          )}
        </Box>
      );
    }
  };
  return (
    <>
      <StyledH3>{children}</StyledH3>
      <StyledDropzone {...getRootProps()} isDragActive={isDragActive}>
        <Box sx={{ width: '90%' }}>
          <input accept={'.csv,.xlsx,.pdf'} type="file" {...getInputProps()} />
          {renderContent()}
        </Box>
      </StyledDropzone>
    </>
  );
};

export default FileUpload;
