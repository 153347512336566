import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ComponentLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Adjust the height as per the requirement of where it's used
        width: "100%", // This ensures the loader is centered in the container
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default ComponentLoading;
