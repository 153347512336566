import {
  useState,
  useEffect,
  lazy,
  Suspense,
  Dispatch,
  SetStateAction,
} from 'react';
import { StyledBoxPaper } from '../../../components/StyledBox/StyledBox.styled';
import { StyledH4 } from '../../../components/Typography/Typography.styled';
import { parseCSV, CSVRow } from '../../../utils/dataParser';
import { toast } from 'react-toastify';
import CustomAgGrid from '../../../components/CustomAgGrid/CustomAgGrid';
import useScroll from '../../../hooks/useScroll';
// const CustomAgGrid = lazy(
//   () => import('../../../components/CustomAgGrid/CustomAgGrid'),
// );

type CSVPreviewProps = {
  csvUrl: string;
  headers: string[];
  setHeaders: Dispatch<SetStateAction<string[]>>;
  uploadedCSVName: string;
};

const CSVPreview = ({
  csvUrl,
  headers,
  setHeaders,
  uploadedCSVName,
}: CSVPreviewProps) => {
  const [previewData, setPreviewData] = useState<CSVRow[]>([]);

  const previewRef = useScroll(previewData);

  useEffect(() => {
    const fetchData = async () => {
      if (!csvUrl) {
        setPreviewData([]);
        setHeaders([]);
        return;
      }
      try {
        const response = await fetch(csvUrl);
        if (response.status !== 200) {
          toast.error('Failed to load CSV preview.');
          return;
        }
        const fileData = await response.text();
        const { data, fields } = await parseCSV(fileData);
        setPreviewData(data);
        setHeaders(fields);
      } catch (error) {
        console.error('Error fetching or parsing CSV', error);
        toast.error('Failed to load or parse CSV data.');
      }
    };
    fetchData();
  }, [csvUrl]);

  const columns = headers.map((header) => ({
    field: header,
    headerName: header.toUpperCase(),
  }));

  if (
    !csvUrl ||
    !previewData ||
    previewData.length === 0 ||
    columns.length === 0
  ) {
    return null;
  }
  return (
    <StyledBoxPaper
      ref={previewRef}
      sx={{
        height: '400px',
        paddingBottom: 0,
      }}
    >
      <StyledH4 sx={{ fontWeight: 'bold' }}>
        Preview : {uploadedCSVName}
      </StyledH4>
      <CustomAgGrid
        columnDefs={columns}
        rowData={previewData}
        height="100%"
        marginTop="0.5rem"
      />
    </StyledBoxPaper>
  );
};

export default CSVPreview;
