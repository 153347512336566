// SharedDataProvider.js
import { ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SharedDataContext from './SharedDataContext';
import ChatInitializationContext from './ChatInitializationContext';
import AnalysisCompletionFlagsContext from './AnalysisCompletionFlags';
import { useAuth0 } from '@auth0/auth0-react';
import {
  SharedData,
  AnalysisCompletionFlags,
  ChatInitialization,
} from './types/contextTypes';

type SharedDataProviderProps = {
  children: ReactNode;
};

// Utility functions for local storage management
const getFromLocalStorage = (key: string, defaultValue: any = null) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error(`Error getting item from local storage: ${error}`);
    return defaultValue;
  }
};

const setToLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting item in local storage: ${error}`);
  }
};

const initialSessionId = () => {
  const storedSessionId = getFromLocalStorage('sessionId', uuidv4());
  setToLocalStorage('sessionId', storedSessionId);
  return storedSessionId;
};

const SharedDataProvider = ({ children }: SharedDataProviderProps) => {
  const { user } = useAuth0();
  const [sessionId, setSessionId] = useState<string>(initialSessionId);
  const userSub = user?.sub;
  const auth0User = user;

  const [csvUrl, setCSVurl] = useState(() => getFromLocalStorage('csvUrl', ''));
  const [uploadedCSVName, setUploadedCSVName] = useState(() =>
    getFromLocalStorage('uploadedCSVName', ''),
  );
  const [uploadedPDFName, setUploadedPDFName] = useState(() =>
    getFromLocalStorage('uploadedPDFName', ''),
  );

  const [selectedVariable, setSelectedVariable] = useState(() =>
    getFromLocalStorage('selectedVariable', ''),
  );
  const availableLLM = ['gpt-4', 'cohere'];
  const [selectedLLM, setSelectedLLM] = useState(() =>
    getFromLocalStorage('selectedLLM', availableLLM[0]),
  );
  const [selectedIndustry, setSelectedIndustry] = useState(() =>
    getFromLocalStorage('selectedIndustry', ''),
  );
  const [businessProblem, setBusinessProblem] = useState(() =>
    getFromLocalStorage('businessProblem', ''),
  );

  //FLAGS TO KEEP TRACK OF ANALYSIS
  const [isDescriptiveStatsDone, setIsDescriptiveStatsDone] = useState(() =>
    getFromLocalStorage('isDescriptiveStatsDone', false),
  );
  const [isAutoMLAnalysisDone, setIsAutoMLAnalysisDone] = useState(() =>
    getFromLocalStorage('isAutoMLAnalysisDone', false),
  );
  const [isLinearModelDone, setIsLinearModelDone] = useState(() =>
    getFromLocalStorage('isLinearModelDone', false),
  );
  const [recommendations, setRecommendations] = useState(() =>
    getFromLocalStorage('recommendations', ''),
  );
  const [recommendationsUUID, setRecommendationsUUID] = useState(() =>
    getFromLocalStorage('recommendationsUUID', ''),
  );

  const [problemType, setProblemType] = useState(() =>
    getFromLocalStorage('problemType', ''),
  );

  //CHAT WITH DATA CONTEXT
  const [isChatInitialized, setChatInitialized] = useState(() =>
    getFromLocalStorage('isChatInitialized', false),
  );

  const updateCSVurl = (newValue: string) => {
    setCSVurl(newValue);
    setToLocalStorage('csvUrl', newValue);
    updateProblemType('');
    generateNewSessionId();
  };

  const updateUploadedCSVName = (newValue: string) => {
    setUploadedCSVName(newValue);
    setToLocalStorage('uploadedCSVName', newValue);
    generateNewSessionId();
    updateSelectedVariable('');
  };

  const updateUploadedPDFName = (newValue: string) => {
    setUploadedPDFName(newValue);
    setToLocalStorage('uploadedPDFName', newValue);
    generateNewSessionId();
  };

  const updateSelectedVariable = (newValue: string) => {
    setSelectedVariable(newValue);
    setToLocalStorage('selectedVariable', newValue);
    resetAnalysisFlags();
  };

  const updateSelectedLLM = (newValue: string) => {
    setSelectedLLM(newValue);
    setToLocalStorage('selectedLLM', newValue);
    resetAnalysisFlags();
    generateNewSessionId();
  };

  const updateSelectedIndustry = (newValue: string) => {
    setSelectedIndustry(newValue);
    setToLocalStorage('selectedIndustry', newValue);
    resetAnalysisFlags();
  };

  const updateBusinessProblem = (newValue: string) => {
    setBusinessProblem(newValue);
    setToLocalStorage('businessProblem', newValue);
    resetAnalysisFlags();
  };

  const updateIsDescriptiveStatsDone = (newValue: boolean) => {
    setIsDescriptiveStatsDone(newValue);
    setToLocalStorage('isDescriptiveStatsDone', newValue);
  };

  const updateIsAutoMLAnalysisDone = (newValue: boolean) => {
    setIsAutoMLAnalysisDone(newValue);
    setToLocalStorage('isAutoMLAnalysisDone', newValue);
  };

  const updateIsLinearModelDone = (newValue: boolean) => {
    setIsLinearModelDone(newValue);
    setToLocalStorage('isLinearModelDone', newValue);
  };

  const updateIsChatInitialized = (newValue: boolean) => {
    setChatInitialized(newValue);
    setToLocalStorage('isChatInitialized', newValue);
  };

  const updateRecommendations = (newValue: string) => {
    setRecommendations(newValue);
    setToLocalStorage('recommendations', newValue);
  };

  const updateRecommendationsUUID = (newValue: string) => {
    setRecommendationsUUID(newValue);
    setToLocalStorage('recommendationsUUID', newValue);
  };

  const updateProblemType = (newValue: string) => {
    setProblemType(newValue);
    setToLocalStorage('problemType', newValue);
  };

  //Reset Analysis flags in context and local storage
  const resetAnalysisFlags = () => {
    updateIsDescriptiveStatsDone(false);
    updateIsAutoMLAnalysisDone(false);
    updateIsLinearModelDone(false);
    updateRecommendations('');
    updateRecommendationsUUID('');
    updateIsChatInitialized(false);
  };

  const resetUserSession = () => {
    updateCSVurl('');
    updateUploadedCSVName('');
    updateUploadedPDFName('');
    updateSelectedVariable('');
    updateSelectedLLM(availableLLM[0]);
    updateSelectedIndustry('');
    updateBusinessProblem('');
    updateProblemType('');
    resetAnalysisFlags();
    generateNewSessionId();
  };

  const generateNewSessionId = () => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId); // Update the sessionId state
    setToLocalStorage('sessionId', newSessionId); // Update sessionId in local storage
    resetAnalysisFlags();
  };

  const sharedData: SharedData = {
    sessionId,
    userSub,
    auth0User,
    csvUrl,
    updateCSVurl,
    uploadedCSVName,
    updateUploadedCSVName,
    uploadedPDFName,
    updateUploadedPDFName,
    selectedVariable,
    updateSelectedVariable,
    availableLLM,
    selectedLLM,
    updateSelectedLLM,
    selectedIndustry,
    updateSelectedIndustry,
    businessProblem,
    updateBusinessProblem,
    resetUserSession,
  };

  const analysisCompletionFlags: AnalysisCompletionFlags = {
    isDescriptiveStatsDone,
    updateIsDescriptiveStatsDone,
    isAutoMLAnalysisDone,
    updateIsAutoMLAnalysisDone,
    isLinearModelDone,
    updateIsLinearModelDone,
    recommendations,
    updateRecommendations,
    recommendationsUUID,
    updateRecommendationsUUID,
    problemType,
    updateProblemType,
  };

  const chatInitialization: ChatInitialization = {
    isChatInitialized,
    updateIsChatInitialized,
  };

  return (
    <SharedDataContext.Provider value={sharedData}>
      <AnalysisCompletionFlagsContext.Provider value={analysisCompletionFlags}>
        <ChatInitializationContext.Provider value={chatInitialization}>
          {children}
        </ChatInitializationContext.Provider>
      </AnalysisCompletionFlagsContext.Provider>
    </SharedDataContext.Provider>
  );
};

export default SharedDataProvider;
