import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';

export const StyledAppBar = styled(AppBar)(({ theme, elevation = 0 }) => ({
  backgroundColor:
    elevation > 0
      ? theme.palette.background.paper
      : theme.palette.background.default,
  flex: 'grow',
  justifyContent: 'center',
  position: 'sticky',
  boxShadow: elevation > 0 ? theme.shadows[1] : 'none',
  padding: '2.2rem 0 0.6rem 0',
  borderRadius:
    elevation > 0
      ? `0 0 ${theme.shape.borderRadius} ${theme.shape.borderRadius}`
      : 'none',
  border: elevation > 0 ? `1px solid ${theme.palette.divider}}` : 'none',
  zIndex: elevation > 0 ? '5' : '0',
}));

// export const StyledAppBar = styled(AppBar, {
//   shouldForwardProp: (prop) => prop !== 'elevation',
// })(({ theme, elevation = 0 }: StyledAppBarProps) => ({
//   backgroundColor:
//     elevation > 0
//       ? theme.palette.background.paper
//       : theme.palette.background.default,
//   flex: 'grow',
//   justifyContent: 'center',
//   position: 'sticky',
//   boxShadow: elevation > 0 ? theme.shadows[0] : 'none',
//   padding: '2.2rem 0 0.6rem 0',
//   borderRadius:
//     elevation > 0
//       ? `0 0 ${theme.shape.borderRadius} ${theme.shape.borderRadius}`
//       : 'none',
//   border: elevation > 0 ? `1px solid ${theme.palette.divider}}` : 'none',
//   zIndex: elevation > 0 ? '5' : '0',
// }));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1.5rem',
  color: theme.palette.text.primary,
}));

export const SelectedTabTitle = styled(Typography)(() => ({
  fontWeight: 700,
}));

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.background.default,
  },
  '& .MuiList-root': {
    padding: '0',
  },
}));

export const StyledMenuIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main, // Use text color from theme
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Example hover effect
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  padding: '0 1rem',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.typography.h3.color,
}));
