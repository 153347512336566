export type HeaderNameItem = {
  name: string;
  id: number;
  compPath: string;
};

export const HeaderNameItems: HeaderNameItem[] = [
  {
    name: 'Data Upload',
    id: 1,
    compPath: '/',
  },
  {
    name: 'Data Overview & Analysis',
    id: 2,
    compPath: '/data-overview',
  },
  {
    name: 'Analysis with Machine Learning',
    id: 3,
    compPath: '/machine-learning',
  },
  {
    name: 'Analysis with Linear Regression',
    id: 4,
    compPath: '/linear-regression',
  },
  {
    name: 'Recommendations',
    id: 5,
    compPath: '/recommendations',
  },
  {
    name: 'Chat with Data',
    id: 6,
    compPath: '/chat',
  },
  {
    name: 'Glossary',
    id: 7,
    compPath: '/glossary',
  },
  {
    name: 'Feedback Form',
    id: 8,
    compPath: '/feedback',
  },
  {
    name: 'Terms and Conditions',
    id: 9,
    compPath: '/terms-conditions',
  },
];

export const industries: string[] = [
  '',
  'Sports',
  'Media',
  'Logistics',
  'Industrial',
  'Biodiversity',
  'Healthcare',
  'Finance',
  'Education',
  'Retail',
  'Agriculture',
  'Energy',
  'Other',
];
