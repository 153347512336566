import { styled } from '@mui/material/styles';
import { StyledH1, StyledH4 } from '../Typography/Typography.styled';

const Overlay = styled('div')(() => ({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f8f9fa',
  zIndex: '9999',
}));

const MainLoading = () => {
  return (
    <Overlay>
      <StyledH1
        sx={{
          color: '#12b6aa',
          fontWeight: '600',
          fontFamily: "'Roboto Mono', 'Helvetica', monospace",
        }}
      >
        Scavenger
      </StyledH1>
      <StyledH4
        sx={{
          color: '#000000',
          fontWeight: '400',
          fontFamily: "'Roboto Mono',monospace",
        }}
      >
        {' '}
        Loading...{' '}
      </StyledH4>
    </Overlay>
  );
};

export default MainLoading;
