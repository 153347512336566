import { styled } from '@mui/material/styles';
import { StyledH4 } from '../../components/Typography/Typography.styled';

export const StyledForm = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1rem 0',
}));

export const StyledFormRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '& > *:first-child': {
    width: '25%',
  },
  '& > *:last-child': {
    width: '75%',
  },
}));

export const StyledFieldname = styled(StyledH4)(({ theme }) => ({
  margin: 0,
  padding: 0,
  fontSize: '16px',
  fontWeight: '600',
}));
