import { useContext, useState } from 'react';
import { StyledCard } from '../../components/StyledCard/StyledCard.styled';
import {
  StyledH3,
  StyledText,
} from '../../components/Typography/Typography.styled';
import { StyledTextField } from '../../components/StyledTextField/StyledTextField.styled';
import { StyledButton } from '../../components/Button/Button.styled';
import SharedDataContext from '../../context/SharedDataContext';
import { useAuth0 } from '@auth0/auth0-react';
import {
  StyledForm,
  StyledFieldname,
  StyledFormRow,
} from './FeedbackForm.styled';
import { UploadAxiosContext } from '../../context/AxiosContext';
import { toast } from 'react-toastify';
import { Box } from '@mui/system';

const FeedbackForm = () => {
  const { user } = useAuth0();
  const {
    uploadedCSVName,
    uploadedPDFName,
    selectedLLM,
    selectedIndustry,
    businessProblem,
    selectedVariable,
    sessionId,
  } = useContext(SharedDataContext);
  const uploadAxiosInstance = useContext(UploadAxiosContext);
  const [userFeedback, setUserFeedback] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFeedbackChange = (feedback: string) => {
    setUserFeedback(feedback);
  };

  const handleSubmit = async () => {
    console.log('submit form');
    setLoading(true);
    const baseParams = {
      session_id: sessionId,
      user_email: user?.email || '',
      user_name: user?.name || '',
      csv_file_name: uploadedCSVName,
      pdf_file_name: uploadedPDFName,
      target_variable: selectedVariable,
      selected_llm: selectedLLM,
      selected_industry: selectedIndustry,
      business_problem: businessProblem,
      feedback_content: userFeedback,
    };

    const endpoint = '/feedback_form';
    const params = new URLSearchParams(baseParams).toString();
    const url = `${endpoint}?${params}`;
    try {
      if (!uploadAxiosInstance) return;
      const response = await uploadAxiosInstance.get(url);
      if (response.data.status_code >= 400) {
        throw new Error(response.data.message || 'API response error');
      }
      toast.success(
        'Your feedback has been submitted!' || response.data.message,
      );
      setUserFeedback('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('An error occurred while submitting feedback.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledCard>
        <StyledH3>Feedback Form</StyledH3>
        <StyledText>
          We value your feedback to help us improve our service. Please provide
          any comments or issues you've encountered in the form below.
        </StyledText>
        <StyledText sx={{ display: 'block', marginBottom: '1.5rem' }}>
          The following information will be included with your feedback to
          assist us in addressing your concerns more effectively.
        </StyledText>
        <StyledForm>
          <StyledFormRow>
            <StyledFieldname>Username:</StyledFieldname>
            <StyledText>{user?.name}</StyledText>
          </StyledFormRow>
          <StyledFormRow>
            <StyledFieldname>User Email:</StyledFieldname>
            <StyledText>{user?.email}</StyledText>
          </StyledFormRow>

          <StyledFormRow>
            <StyledFieldname>CSV File Name:</StyledFieldname>
            <StyledText>{uploadedCSVName}</StyledText>
          </StyledFormRow>
          <StyledFormRow>
            <StyledFieldname>PDF File Name:</StyledFieldname>
            <StyledText>{uploadedPDFName}</StyledText>
          </StyledFormRow>
          <StyledFormRow>
            <StyledFieldname>Target Variable:</StyledFieldname>
            <StyledText>{selectedVariable}</StyledText>
          </StyledFormRow>
          <StyledFormRow>
            <StyledFieldname>Selected LLM:</StyledFieldname>
            <StyledText>{selectedLLM}</StyledText>
          </StyledFormRow>
          <StyledFormRow>
            <StyledFieldname>Selected Industry:</StyledFieldname>
            <StyledText>{selectedIndustry}</StyledText>
          </StyledFormRow>
          <StyledFormRow>
            <StyledFieldname>Business Problem:</StyledFieldname>
            <StyledText>{businessProblem}</StyledText>
          </StyledFormRow>
        </StyledForm>
        <div>
          <StyledFieldname>
            Please describe the issue or provide your feedback:
          </StyledFieldname>
        </div>
        <StyledTextField
          value={userFeedback}
          onChange={(event) => handleFeedbackChange(event.target.value)}
          rows={2}
          multiline
          required
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
        >
          <StyledButton
            disabled={!userFeedback || loading}
            onClick={handleSubmit}
          >
            Submit
          </StyledButton>
        </Box>
      </StyledCard>
    </>
  );
};

export default FeedbackForm;
