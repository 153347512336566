import { useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import {
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
} from '../DataUpload.styled';
import { StyledCard } from '../../../components/StyledCard/StyledCard.styled';
import {
  StyledH3,
  StyledH4,
} from '../../../components/Typography/Typography.styled';
import SharedDataContext from '../../../context/SharedDataContext';
import { UploadAxiosContext } from '../../../context/AxiosContext';
import AnalysisCompletionFlagsContext from '../../../context/AnalysisCompletionFlags';
import { toast } from 'react-toastify';

type TargetVariableSelectProps = {
  variables: string[];
};

const TargetVariableSelect = ({ variables }: TargetVariableSelectProps) => {
  const uploadAxiosInstance = useContext(UploadAxiosContext);
  const { uploadedCSVName, selectedVariable, updateSelectedVariable } =
    useContext(SharedDataContext);
  const { problemType, updateProblemType } = useContext(
    AnalysisCompletionFlagsContext,
  );

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (!uploadAxiosInstance) return;
    const targetValue = event.target.value;
    updateSelectedVariable(targetValue);
    const params = new URLSearchParams({
      csv_url: `s3://${process.env.AWS_BUCKET_NAME}/${uploadedCSVName}`,
      target_column_name: targetValue,
    });
    try {
      const response = await uploadAxiosInstance.get(
        `/choose_target_variable?${params.toString()}`,
      );
      if (response.data.status_code >= 400) {
        throw new Error(response.data.message || 'API response error');
      }
      updateProblemType(response.data.problem_type);
      // TODO: Remove when support for classification problem type is added
      if (response.data.problem_type !== 'Regression') {
        toast.info(
          'Only regression problem type is supported. Please select a different target variable.',
        );
      }
    } catch (error: any) {
      console.error('Error fetching data:', error);
      toast.error(
        error.message ||
        'Failed to fetch problem type for the selected variable.',
      );
    }
  };
  return (
    <StyledCard>
      <StyledH3 sx={{ marginBottom: '0.75rem' }}>
        Select the Target Variable
      </StyledH3>
      <FormControl fullWidth>
        <StyledInputLabel id="variable-select-label">
          Select the variable from your dataset that you would like to explain,
          optimize, or predict:
        </StyledInputLabel>
        <StyledSelect
          labelId="variable-select-label"
          id="variable-select"
          value={selectedVariable}
          onChange={(event: any) => handleSelectChange(event)}
        >
          {variables.map((variable) => (
            <StyledMenuItem key={variable} value={variable}>
              {variable.toUpperCase()}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>

      {problemType && (
        <StyledH4 sx={{ marginTop: '1.25rem' }}>
          The Problem type is{' '}
          <span
            style={{
              fontWeight: '700',
              textDecoration: 'underline',
              color: '#12B6AA',
            }}
          >
            {problemType}.
          </span>
        </StyledH4>
      )}
    </StyledCard>
  );
};

export default TargetVariableSelect;
